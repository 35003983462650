import axios from './BaseService';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export async function doLogin(email, password) {
    const loginUrl = `${API_URL}/login`;
    const response = await axios.post(loginUrl, { email, password });
    return response.data;
}

export async function doRecovery(secretPhrase) {
    const recoveryUrl = `${API_URL}/recovery`;
    const response = await axios.post(recoveryUrl, { secretPhrase });
    return response.data;
}

export async function doLogout() {
    const logoutUrl = `${API_URL}/logout`;
    const response = await axios.post(logoutUrl, {});
    return response.data;
}

export async function saveUser(newUser) {
    const signupUrl = `${API_URL}/signup`;
    const response = await axios.post(signupUrl, newUser);
    return response.data;
}

export async function getCode(email) {
    const codeUrl = `${API_URL}/code`;
    const response = await axios.post(codeUrl, { email });
    return response.data;
}