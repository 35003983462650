// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Adiciona suporte ao React
  .init({
    resources: {
      en: {
        translation: {
          'Your Email': 'Your Email',
          'Your Password': 'Your Password',
          'Sign in to': 'Sign in to',
          'Remember me': 'Remember me',
          'Lost password': 'Lost password',
          'Dashboard': 'Dashboard',
          'Active Users': 'Active Users',
          'Volume Transaction': 'Volume Transaction',
          'Welcome': 'Welcome',
          'Intelligence': 'Intelligence',
          'Wallet': 'Wallet',
          'Reports': 'Reports',
          'Orders': 'Orders',
          'Transactions': 'Transactions',
          'Withdraw': 'Withdraw',
          'Automations': 'Automations',
          'Monitors': 'Monitors',
          'Settings': 'Settings',
          'Personal': 'Personal',
          'Symbols': 'Symbols',
          'Logout': 'Logout',
          'Active': 'Active',
          'Users': 'Users',
          'Volume': 'Volume',
          'Transaction': 'Transaction',
          'Loading...': 'Loading...',
          'Available': 'Available',
          'Locked': 'Locked',
          'Fiat': 'Fiat',
          'Avg Price': 'Avg Price',
          'Toast': 'Toast',
          'New': 'New',
          'Withdraw': 'Withdraw',
          'Order': 'Order',
          'Book': 'Book',
          'SYMBOL': 'SYMBOL',
          'BID': 'BID',
          'ASK': 'ASK',
          'CLOSE': 'CLOSE',
          'OPEN': 'OPEN',
          'HIGH': 'HIGH',
          'LOW': 'LOW',
          'Quantity': 'Quantity',
          'Unit': 'Unit',
          'Price': 'Price',
          'Activation': 'Activation',
          'Callback': 'Callback',
          'Rate': 'Rate',
          'Stop': 'Stop',
          'Send': 'Send',
          'Save': 'Save'
        }
      },
      pt: {
        translation: {
          'Your Email': 'Seu Email',
          'Your Password': 'Sua Senha',
          'Sign in to': 'Entrar em',
          'Remember me': 'Lembrar-me',
          'Lost password': 'Esqueci a senha',
          'Dashboard': 'Painel',
          'Active Users': 'Usuarios Ativos',
          'Volume Transaction': 'Volume de Transações',
          'Welcome': 'Bem-vindo',
          'Intelligence': 'Inteligência',
          'Wallet': 'Carteira',
          'Reports': 'Relatórios',
          'Orders': 'Ordens',
          'Transactions': 'Transações',
          'Withdraw': 'Retirar',
          'Automations': 'Automações',
          'Monitors': 'Monitores',
          'Settings': 'Configurações',
          'Personal': 'Pessoal',
          'Symbols': 'Símbolos',
          'Logout': 'Sair',
          'Active': 'Ativo',
          'Users': 'Usuários',
          'Volume': 'Volume',
          'Transaction': 'Transação',
          'Loading...': 'Carregando...',
          'Available': 'Disponível',
          'Locked': 'Bloqueado',
          'Fiat': 'Fiat',
          'Avg Price': 'Preço Médio',
          'Toast': 'Toast',
          'New': 'Novo',
          'Withdraw': 'Retirar',
          'Order': 'Ordem',
          'Book': 'Livro',
          'SYMBOL': 'SÍMBOLO',
          'BID': 'OFERTA',
          'ASK': 'PEDIR',
          'CLOSE': 'FECHOU',
          'OPEN': 'ABRIU',
          'HIGH': 'ALTO',
          'LOW': 'BAIXO',
          'Quantity': 'Quantidade',
          'Unit': 'Unidade',
          'Price': 'Preço',
          'Activation': 'Ativação',
          'Callback': 'Retorno',
          'Rate': 'Taxa',
          'Stop': 'Parar',
          'Send': 'Enviar',
          'Save': 'Salvar'
        }
      },
      // Adicione recursos para outros idiomas aqui
    },
    lng: 'en', // Define o idioma padrão
    fallbackLng: 'en', // Idioma de fallback
    interpolation: {
      escapeValue: false // Evita a necessidade de escapar strings
    }
  });

  // Adicione este log para verificar se as traduções estão sendo carregadas corretamente
console.log('Translations:', i18n.t('Your Email'));

export default i18n;