import React, { useState, useEffect, useMemo } from 'react';
import { getSymbols } from '../../../services/SymbolsService';
import '../Dashboard.css'
import SelectQuote, { filterSymbolNames, getDefaultQuote } from '../../../components/SelectQuote/SelectQuote';
import BookRow from './BookRow';

/**
 * props:
 * - data: the market data
 */
function Book(props) {

    const [symbols, setSymbols] = useState([]);

    const [quote, setQuote] = useState(getDefaultQuote());

    const [gold, setGold] = useState({
        bid: '0',
        ask: '0',
    });

    const bookGold = useMemo(() => (
        <tr>
        <td className="text-gray-900">GC24KUSDT</td>
        <td className="text-gray-900">{`${gold.bid}`.substring(0, 8)}</td>
        <td className="text-gray-900">{`${gold.ask}`.substring(0, 8)}</td>
    </tr>), [gold.bid, gold.ask])

    useEffect(() => {
        if (!props.gold || props.gold.b === undefined ) return;

        if (gold.bid !== props.gold.b)
            gold.bid = props.gold.b;

        if (gold.ask !== props.gold.a)
            gold.ask = props.gold.a;

        setGold(gold);
    }, [props.gold]);

    function onQuoteChange(event) {
        setQuote(event.target.value);
    }

    useEffect(() => {
        getSymbols(false)
            .then((symbols) => setSymbols(filterSymbolNames(symbols.rows, quote)))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            })
    }, [quote]);

    return (
        <React.Fragment>
            <div className="col-sm-12 col-md-6 mb-4">
                <div className="card border-0 shadow">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <h2 className="fs-5 fw-bold mb-0">Order Book</h2>
                            </div>
                            <div className="col offset-md-3">
                                <SelectQuote onChange={onQuoteChange} value={quote} isVisible={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive divScroll">
                        <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                            <thead className="thead-light">
                                <tr>
                                    <th className="border-bottom col-2" scope="col">SYMBOL</th>
                                    <th className="border-bottom col-2" scope="col">BID</th>
                                    <th className="border-bottom col-2" scope="col">ASK</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookGold}
                                {symbols && symbols.length ? (
                                    symbols.map(item => {
                                        if (item === 'GC24KUSDT' || item === 'PAXGUSDT' || item === 'REZUSDT') {
                                            return <React.Fragment></React.Fragment>
                                        }
                                        return (
                                            <BookRow key={item} symbol={item} data={props.data[item]} />
                                        );
                                    })
                                ) : (
                                    <React.Fragment />
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Book;
