import React, { useRef, useState, useEffect } from 'react';
import SelectCoin from '../../../components/SelectCoin/SelectCoin';
import QuantityInput from '../../../components/NewOrder/QuantityInput';
import WalletHave from '../../../components/WalletHave/WalletHave';
import { saveWithdrawTemplate } from '../../../services/WithdrawTemplatesService';
import SymbolPrice from './SymbolPrice';
import { getSymbol } from '../../../services/SymbolsService';
import { checkSettings } from '../../../services/SettingsService';
import { getMemoryIndex } from '../../../services/BeholderService';
import InfoGet from './InfoGet/InfoGet';

/**
 *  <th className="border-gray-200">Coin</th>
    <th className="border-gray-200">Date</th>
    <th className="border-gray-200">USD</th>
    <th className="border-gray-200">Status</th>
    <th className="border-gray-200">View</th>
 * props:
 * - data
 * - onSubmit
 */
function WithdrawTemplateModal(props) {

    const DEFAULT_WITHDRAW_TEMPLATE = {
        name: '',
        email: '',
        wasVerified: false,
        status: 'REQUIRED',
        isVerified: false,
        //symbol: '',
        coin: '',
        inputTotal: "0",
        //limitPrice: "0",
        marketPrice: "0",
        baseSymb: "",
        baseSymbQty: "0",       
        quoteSymb: "",
        quoteSymbQty: "0",
        //stopPrice: "0",
        //stopPriceMultiplier: "0",
        quantity: "0",
        type: "MARKET",
        isQuote: false,
        fee: "0",
        total: "0"
    }

    const [error, setError] = useState('');

    const [symbol, setSymbol] = useState({});
    const [coin, setCoin] = useState('');
    
    const [textQuantity, setTextQuantity] = useState('checking value of coin...');
    const [conditionQuantity, setConditionQuantity] = useState(false);

    const [textSettings, settextSettings] = useState('checking account...');
    const [conditionSettings, setConditionSettings] = useState(false);
    const [conditionSettings2, setConditionSettings2] = useState(true);

    const [textMarket, setTextMarket] = useState('checking value of market...');
    const [conditionMarket, setConditionMarket] = useState(false);




    const [withdrawTemplate, setWithdrawTemplate] = useState(DEFAULT_WITHDRAW_TEMPLATE);

    const btnClose = useRef('');
    const btnSave = useRef('');
    const btnVerify = useRef('');
    const inputTotal = useRef('');

    useEffect(() => {
        setError('');
        if (withdrawTemplate.wasVerified === true) {
            btnSave.current.disabled = false;
        }

        const quantity = typeof withdrawTemplate.quantity === 'string'
            ? parseFloat(withdrawTemplate.quantity.replace(',', '.'))
            : withdrawTemplate.quantity;

        const baseQty = typeof withdrawTemplate.baseSymbQty === 'string'
            ? parseFloat(withdrawTemplate.baseSymbQty.replace(',', '.'))
            : withdrawTemplate.baseSymbQty;

        const inputTotal = typeof withdrawTemplate.inputTotal === 'string'
            ? parseFloat(withdrawTemplate.inputTotal.replace(',', '.'))
            : withdrawTemplate.inputTotal;

        if (!withdrawTemplate.isQuote) {
            if (quantity && quantity < 0) {
                btnSave.current.disabled = true;
                setTextQuantity("check your value!");
                setConditionQuantity(false);
                return setError('No valid quantity!');
                
            } else {
                setTextQuantity("value checked.");
                setConditionQuantity(true);
            }

            if (quantity && quantity > baseQty) {
                btnSave.current.disabled = true;
                setTextQuantity("no have coin!");
                setConditionQuantity(false);
                return setError('No valid quantity!');
            }

            if (inputTotal && inputTotal < 100) {
                btnSave.current.disabled = true;
                return setError('No valid quantity!');
            }
        }

        if (!quantity) {
            btnSave.current.disabled = true;
            setTextQuantity("check your value!");
            setConditionQuantity(false);
        }
        if (!inputTotal){
            btnSave.current.disabled = true;
            setTextMarket("await market price...");
            setConditionMarket(false);
        } else{
            setTextMarket("market price checked.");
            setConditionMarket(true);
        }

        


    }, [withdrawTemplate.quantity, withdrawTemplate.inputTotal])

    // PEGA SYMBOL
    useEffect(() => {
        if (!coin) return;

        getSymbol(coin + 'USDT')
            .then(symbol => {
                if (symbol) return setSymbol(symbol);
                throw new Error(`Symbol not found`);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                return setError(err.response ? err.response.data : err.message);
            })
    }, [coin])

    const [wallet, setWallet] = useState({ base: { symbol: '', qty: 0 }, quote: { symbol: '', qty: 0 } });

    // Dentro da função loadWallet
    async function loadWallet(coin) {

        //const symbol = coin + "USDT"; // Concatenando a moeda com "USDT"
        try {
            const baseQty = await getMemoryIndex(coin, 'WALLET', null);
            const quoteQty = await getMemoryIndex('USDT', 'WALLET', null);

            setWithdrawTemplate(prevState => ({ ...prevState, baseSymb: coin }));
            setWithdrawTemplate(prevState => ({ ...prevState, baseSymbQty: parseFloat(baseQty) }));       
            setWithdrawTemplate(prevState => ({ ...prevState, quoteSymb: 'USDT' }));
            setWithdrawTemplate(prevState => ({ ...prevState, quoteSymbQty: parseFloat(quoteQty) }));

            setWallet({ base: { qty: baseQty, symbol: coin }, quote: { qty: quoteQty, symbol: 'USDT' } });

        } catch (err) {
            console.log(err => err.response ? err.response.data : err.message);
            setError("You no have coin!");
        }
    }

    function onInputChange(event) {
        const { id, value } = event.target;
        if (id === "quantity") {
            setWithdrawTemplate(prevState => ({ ...prevState, [id]: value }));
            // Desabilita o botão se o QuantityInput for 0
        } else if (id === "coin") {
            setWithdrawTemplate(prevState => ({ ...prevState, coin: value }));
            setCoin(value); // Atualiza o símbolo correspondente
        } else {
            setWithdrawTemplate(prevState => ({ ...prevState, [id]: value }));
        }
    }

    //CALCULA O VALOR FINAL
    function onPriceChange(book) {
        const quantity = parseFloat(withdrawTemplate.quantity);
        if (quantity) {
            const assetAmount = `${quantity * parseFloat(book.bid)}`.substring(0, 8);
            const comission = 0.005;
            const comissionRate = assetAmount * comission;
            const total = assetAmount - comissionRate;
            inputTotal.current.value = total.toFixed(2);
            if(book.bid){
                setWithdrawTemplate(prevState => ({
                    ...prevState,
                    marketPrice: parseFloat(book.bid),
                    inputTotal: inputTotal.current.value,
                    fee: comissionRate,
                    total: total.toFixed(2)
                }));
                //setBookAvailable(true); // Se o book.bid estiver disponível, habilita o botão
            }
        }

    }

    function onSubmit(event) {
        
        
        
        saveWithdrawTemplate(withdrawTemplate.id, withdrawTemplate)
            .then(result => {
                btnSave.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                //setError(err.response ? err.response.data : err.message);
            })
        
       //console.log(withdrawTemplate);   
    }

    /*
    checkSettings
    function onInputChange(event) {
        setWithdrawTemplate(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }
    */

    useEffect(() => {
        if (!coin) return;
        setSymbol(coin  + 'USDT');
        loadWallet(coin);

        if (withdrawTemplate.wasVerified === false){
            btnSave.current.disabled = true;
            settextSettings("settings account not verified.");
            setConditionSettings(false);
            setConditionSettings2(false);
        } else {
            settextSettings("settings account verified.");
            setConditionSettings(true);
            setConditionSettings2(true);
        }

    }, [coin])
    

    useEffect(() => {
        const modal = document.getElementById('modalWithdrawTemplate');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setWithdrawTemplate({ ...DEFAULT_WITHDRAW_TEMPLATE });
        })
    }, [])

    useEffect(() => {
        checkSettings()
            .then(response => {
                console.log(response);
                setWithdrawTemplate(prevState => ({
                    ...prevState,
                    wasVerified: response.wasVerified,
                    name: response.name,
                    email: response.email,
                    
                }));
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                //return setError(err.response ? err.response.data : err.message);
            })
    }, [])


    return (
        <div className="modal fade" id="modalWithdrawTemplate" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">Withdraw</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="coin">Coin:</label>
                                        <SelectCoin coin={withdrawTemplate.coin} onChange={onInputChange} />
                                    </div>
                                    {
                                        coin
                                            ? <SymbolPrice symbol={coin + 'USDT'} onChange={onPriceChange} />
                                            : <React.Fragment></React.Fragment>
                                    }
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <QuantityInput id="quantity" text="Quantity:" quantity={withdrawTemplate.quantity} symbol={symbol} isQuote={withdrawTemplate.isQuote} allowQuote="MARKET" onChange={onInputChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>You have:</label>
                                    </div>
                                    <WalletHave wallet={wallet} />
                                    <div className="row">
                                        {
                                            withdrawTemplate.isQuote
                                                ? <React.Fragment></React.Fragment>
                                                : (
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="total">Total USD:</label>
                                                            <input ref={inputTotal} className="form-control" id="total" type="number" placeholder="0" disabled />
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6 mb-4">
                                            <div className="card border-0 shadow">
                                                <div className="card-body">
                                                    <InfoGet text={textSettings} condition={conditionSettings} precision={0} >
                                                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" /></svg>
                                                    </InfoGet>
                                                    <InfoGet text={textMarket} condition={conditionMarket} precision={0} >
                                                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" /></svg>
                                                    </InfoGet>
                                                    <InfoGet text={textQuantity} condition={conditionQuantity} precision={0} >
                                                        <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" /></svg>
                                                    </InfoGet>
                                                    
                                                </div>
                                            </div>
                                        </div>        
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6 mb-4">
                                            <div className="alert alert-warning mt-1 col-12 py-1">Before withdrawing funds, it's crucial to verify your personal and banking details. Verification may take up to 48 hours.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-6 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        { 
                            conditionSettings2
                                ? <React.Fragment></React.Fragment>
                                : <button ref={btnVerify} type="button" className="btn btn-sm btn-secondary" >Verify Account</button>
                        }
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit} >Request</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithdrawTemplateModal;
