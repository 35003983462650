import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import { getSettings, updateSettings } from '../../services/SettingsService';
import WithdrawTemplateModal from '../WithdrawTemplates/WithdrawTemplateModal/WithdrawTemplateModal';

function Settings() {

    const confirmPassword = useRef('');
    const [settings, setSettings] = useState({});
    const [notification, setNotification] = useState({});
    const [editWithdrawTemplate, setEditWithdrawTemplate] = useState({});

    useEffect(() => {

        getSettings()
            .then(result => {
                console.log(result);
                setSettings(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }, []);

    const history = useHistory();

    function onInputChange(event) {
        setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function onCheckboxChange(event) {
        setSettings(prevState => ({ ...prevState, "2FA": event.target.checked }));
    }

    function onFormSubmit(event) {
        event.preventDefault();
        if ((settings.password || confirmPassword.current.value) && settings.password !== confirmPassword.current.value)
            return setNotification({ type: 'error', text: `The fields New Password and Confirm Password must be equal.` });

        // Crie um novo objeto com os campos desejados
        const updatedSettings = {
            name: settings.name,
            email: settings.email,
            password: settings.password,
            phone: settings.phone,
            "2FA": settings["2FA"],
        };

        updateSettings(updatedSettings)
            .then(result => {
                if (result)
                    setNotification({ type: 'success', text: `Settings saved successfully!` });
                else
                    setNotification({ type: 'error', text: result });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    function onWithdrawTemplateSubmit(template) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Settings</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Personal Settings</h2>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input className="form-control" id="name" type="text" placeholder="" value={settings.name || ''} readOnly/>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input className="form-control" id="email" type="email" placeholder="name@company.com" value={settings.email || ''} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="password">New Password</label>
                                        <input className="form-control" id="password" type="password" placeholder="Enter your new password" value={settings.password || ''} onChange={onInputChange} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input ref={confirmPassword} className="form-control" id="confirmPassword" type="password" placeholder="Your new password again" />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-md-6 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="2FA" checked={settings["2FA"]} onChange={onCheckboxChange} />
                                        <label className="form-check-label" htmlFor="2FA">Enable 2FA</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="secretPhrase">Secret Phrase</label>
                                        <textarea
                                            className="form-control"
                                            id="secretPhrase"
                                            value={settings.phrases || ''}
                                            onChange={onInputChange}
                                            readOnly
                                            style={{ width: '100%', height: 'auto', minHeight: '80px', resize: 'none', overflow: 'hidden', whiteSpace: 'normal' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
            <WithdrawTemplateModal data={editWithdrawTemplate} onSubmit={onWithdrawTemplateSubmit} />
            <Toast text={notification.text} type={notification.type} />
        </React.Fragment >
    );
}

export default Settings;
