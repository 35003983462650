import React, { useState, useEffect } from 'react';

const DEFAULT_QUOTE_PROPERTY = "defaultQuote";

function SelectQuote(props) {
    const [quote, setQuote] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // Padrão definido como true

    useEffect(() => {
        if (!props.value) return setQuote(getDefaultQuote());
        setQuote(props.value);
    }, [props.value])

    useEffect(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled])

    useEffect(() => {
        setIsVisible(props.isVisible);
    }, [props.isVisible])

    function onChange(event) {
        setDefaultQuote(event.target.value);
        props.onChange(event);
    }

    return isVisible ? (
        <select id="selectQuote" className="form-select me-4" disabled={isDisabled} value={quote} onChange={onChange}>
            {props.noFavorites ? null : <option value="FAVORITES">Favorites</option>}
            <option value="USDT">USDT</option>
        </select>
    ) : <React.Fragment />;
}

export function filterSymbolObjects(symbols, quote) {
    return symbols.filter(s => {
        if (quote === 'FAVORITES')
            return s.isFavorite;
        else
            return s.symbol.endsWith(quote);
    })
}

export function filterSymbolNames(symbols, quote) {
    return filterSymbolObjects(symbols, quote).map(s => s.symbol);
}

export function getDefaultQuote() {
    return localStorage.getItem(DEFAULT_QUOTE_PROPERTY) ? localStorage.getItem(DEFAULT_QUOTE_PROPERTY) : "USDT";
}

export function setDefaultQuote(quote) {
    localStorage.setItem(DEFAULT_QUOTE_PROPERTY, quote);
}

export default SelectQuote;
