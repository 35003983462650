import React, { useMemo } from 'react';


/**
 * props:
 * - wallet
 */
function WalletHave(props) {

    function getText(qty) {
        const qtyStr = `${qty}`;
        if (qtyStr.indexOf('.') === -1) return qtyStr;

        const decimals = qty === 0 ? 0 : qtyStr.split('.')[1].length;
        //return qty.toFixed(decimals);
        return qty.toFixed(6);
    }

    let baseSymbol = props.wallet.base.symbol; //=== 'PAXG' ? 'GC' : props.wallet.base.symbol;

    const walletSummary = useMemo(() => (
        <div className="row">
            <div className="col-md-12 mb-3">
                <div className="form-group">
                    <div className="alert alert-success py-1">
                        {`${baseSymbol}: ${props.wallet.base.qty ? getText(props.wallet.base.qty) : 0}`}
                    </div>
                </div>
            </div>
        </div>
    ), [props.wallet.base, props.wallet.quote])

    return walletSummary;
}

export default WalletHave;
