import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import NewWithdrawTemplateButton from './NewWithdrawTemplateButton';
import WithdrawTemplateRow from './WithdrawTemplateRow';
import Pagination from '../../components/Pagination/Pagination';
import ViewTemplateModal from './ViewTemplateModal';
import WithdrawTemplateModal from './WithdrawTemplateModal/WithdrawTemplateModal';
import Toast from '../../components/Toast/Toast';
import { getWithdrawTemplates } from '../../services/WithdrawTemplatesService';

function WithdrawTemplates() {

    useEffect(() => {
        if (window.location.href.indexOf('localhost') !== -1)
            setNotification({ type: 'info', text: 'These features only work in production.' });
    }, [])

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    const [withdrawTemplates, setWithdrawTemplates] = useState([]);

    const [notification, setNotification] = useState([]);

    const [count, setCount] = useState(0);

    const [viewWithdrawTemplate, setViewWithdrawTemplate] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [refresh, setRefresh] = useState(0);
    //const [editWithdrawTemplate, setEditWithdrawTemplate] = useState({});

    const [page, setPage] = useState(getPage());

    useEffect(() => {
        
        getWithdrawTemplates('', page || 1)
            .then(result => {         
                setWithdrawTemplates(result.rows ? result.rows : []);
                setCount(result.count);
                setViewWithdrawTemplate(result.rows[0]);
                setIsLoading(false);
                //setEditWithdrawTemplate(result.rows && result.rows.length > 0 ? result.rows[0] : {});
                
               console.log(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
        
    }, [page])



    function onWithdrawTemplateSubmit(template) {
        setRefresh(Date.now());
    }

    function onViewClick(event) {
        const id = event.target.id.replace('view', '');
        const template = withdrawTemplates.find(o => o.id == id);
        // eslint-disable-next-line
        setViewWithdrawTemplate({ ...template });
    }

    /*
    function toggleMarket() {
        if (showFutures())
            history.push('/orders/');
        else
            history.push('/forders/');
        setRefresh(Date.now());
    }
    */

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Withdraw</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewWithdrawTemplateButton />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Coin</th>
                                <th className="border-gray-200">Date</th>
                                <th className="border-gray-200">Qty</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !isLoading && withdrawTemplates && withdrawTemplates.length
                                    ? withdrawTemplates.map(ot => (<WithdrawTemplateRow key={ot.id} data={ot} onClick={onViewClick} />))
                                    : <tr><td colSpan={6}>Loading...</td></tr>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <ViewTemplateModal data={viewWithdrawTemplate} onUpdate={onWithdrawTemplateSubmit} />
            <WithdrawTemplateModal/>
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    );
}

export default WithdrawTemplates;
