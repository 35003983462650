import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { doLogin, doRecovery, getCode } from '../../services/AuthService';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';

function Login() {

    const { t } = useTranslation();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(['', '', '', '', '']);
    const [newCode, setCodeSend] = useState('');
    const [secretPhrase, setSecretPhrase] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1); // Controla o estado da etapa do processo de login
    const codeInputsRef = useRef([]);

    function onChangeInput(event) {
        const { id, value } = event.target;
        if (id === 'email') setEmail(value);
        else if (id === 'password') setPassword(value);
    }

    function onCodeChange(index, value) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move para o próximo campo de entrada após inserir um número
        if (value !== '' && index < code.length - 1) {
            codeInputsRef.current[index + 1].focus();
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        if (step === 1) {
            // Primeira etapa: login com email e senha
            doLogin(email, password)
                .then(response => {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('hasFutures', response.hasFutures);
                    localStorage.setItem('id', response.id);
                    //console.log(response.twoFa);
                    if (response.twoFa === false) {
                        history.push('/dashboard');
                    } else {    
                        getCode(email)
                            .then(result => {
                                setCodeSend(result);
                                setStep(2);
                            })
                            .catch(err => {
                                console.error(err);
                                setError('Invalid code!');
                            });
                    }
                })
                .catch(err => {
                    console.error(err);
                    setError('Invalid user and/or password!');
                });
        } else if (step === 2) {
            const codeStr = code.join('');
            if (codeStr === newCode) {
                history.push('/dashboard');
            } else {
                setError('Invalid code!');
            }
        } else if (step === 3) {
            onRecoverPassword();
        } else if (step === 4) { history.go(0); }
    }

    function onLostPassword() {
        setStep(3); // Muda para a terceira etapa (SRP)
    }

    function onRecoverPassword() {
        // Lógica para recuperar a senha usando a Secret Recovery Phrase (SRP)
        doRecovery(secretPhrase)
            .then(response => {
                setStep(4);
                setError('');
                setEmail(response.email); // response deve conter o email retornado na resposta
            })
            .catch(err => {
                console.error(err);
                setError('Invalid Secret Recovery Phrase (SRP)!');
            });
    }

    /*<LanguageSelector />*/

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                <div className="container">
                    <p className="text-center">
                        <a href="https://goldcoin24k.com" className="d-flex align-items-center justify-content-center">
                            <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path>
                            </svg>
                            {t('Back to homepage')}
                        </a>
                    </p>
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="bg-black shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                            <div className="text-center">
                                <img src="/img/favicon/mstile-150x150.png" alt="Beholder" width={64} />
                            </div>
                            <div className="text-center text-md-center mb-4 mt-md-0">
                                {step === 1 && <h1 className="mb-0 h3 text-gray-601">{t('Sign in to')} GoldCoin24K</h1>}
                                {step === 2 && <h1 className="mb-0 h3 text-gray-601">Enter 2FA Code</h1>}
                                {step === 3 && <h1 className="mb-0 h3 text-gray-601">Secret Recovery Phrase</h1>}
                                {step === 4 && <h1 className="mb-0 h3 text-gray-601 text-center">Password Recovery</h1>}
                            </div>
                            <form action="#" className="mt-4" onSubmit={onSubmit}>
                                {step === 1 && (
                                    <React.Fragment>
                                        <div className="form-group mb-4">
                                            <label htmlFor="email" className="text-gray-601">{t('Your Email')}</label>
                                            <input type="email" className="form-control" placeholder="example@company.com" id="email" autoFocus required onChange={onChangeInput} />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group mb-4">
                                                <label htmlFor="password" className="text-gray-601">{t('Your Password')}</label>
                                                <input type="password" placeholder="Password" className="form-control" id="password" required onChange={onChangeInput} />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="remember" />
                                                    <label className="form-check-label mb-0 text-gray-601" htmlFor="remember">
                                                        {t('Remember me')}
                                                    </label>
                                                </div>
                                                <div><button type="button" className="btn small text-right text-gray-601" onClick={onLostPassword}>{t('Lost password')}?</button></div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {step === 2 && (
                                    <React.Fragment>
                                        <div className="form-group mb-4">
                                            <label htmlFor="code" className="text-gray-601">Code:</label>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {code.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        ref={(el) => codeInputsRef.current[index] = el}
                                                        type="text"
                                                        className="form-control mx-2 text-center rounded-circle border border-gold"
                                                        style={{ width: '50px', height: '50px' }}
                                                        maxLength="1"
                                                        value={digit}
                                                        onChange={(event) => onCodeChange(index, event.target.value)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {step === 3 && (
                                    <React.Fragment>
                                        <div className="mb-3">
                                            <label htmlFor="secretPhrase" className="text-gray-601">Please enter the Secret Recovery Phrase for recovery:</label>
                                            <textarea
                                                className="form-control"
                                                id="secretPhrase"
                                                value={secretPhrase}
                                                onChange={(e) => setSecretPhrase(e.target.value)}
                                                required
                                                style={{ width: '100%', height: 'auto', minHeight: '80px', resize: 'none', overflow: 'hidden', whiteSpace: 'normal' }}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {step === 4 && (
                                    <React.Fragment>
                                        <div className="mb-3 text-center">
                                            <label className="text-gray-601">A new password has been sent to:</label>
                                            <p className="text-gray-601">{email}</p>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="d-grid">
                                    <button type="submit" className="btn btn-gray-800">
                                        <span className="text-gray-601">
                                            {step === 1 ? 'Sign In' : step === 2 ? 'Submit' : step === 3 ? 'Recover Access' : 'Return'}
                                        </span>
                                    </button>
                                </div>
                                {error && <div className="alert alert-danger mt-2">{error}</div>}
                            </form>
                            <div className="d-flex justify-content-center align-items-center mt-4">
                                <span className="fw-normal text-gray-601">
                                Not registered?
                                            <Link to="/signup" className="fw-bold text-gray-601">Create account</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;
