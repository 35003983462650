import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { saveUser, getCode } from '../../services/AuthService';

function SignUp() {
    const history = useHistory();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [code, setCode] = useState(['', '', '', '', '']);
    const [newCode, setCodeSend] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const codeInputsRef = useRef([]);

    const DEFAULT_USER = {
        name: '',
        email: '',
        password: '',
        limitId: 1,
        phone: '',
        isActive: true
    }

    const [user, setUser] = useState(DEFAULT_USER);

    function onAgreeTermsChange(event) {
        setAgreeTerms(event.target.checked);
    }


    function onChangeInput(event) {
        const { id, value } = event.target;
        if (id === 'username') setUser(prevState => ({ ...prevState, name: value }));
        else if (id === 'email') setUser(prevState => ({ ...prevState, email: value }));
        else if (id === 'password') setUser(prevState => ({ ...prevState, password: value }));
        else if (id === 'confirmPassword') setConfirmPassword(value);
        else if (id === 'code') setCode(value);
    }

    function onCodeChange(index, value) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move para o próximo campo de entrada após inserir um número
        if (value !== '' && index < code.length - 1) {
            codeInputsRef.current[index + 1].focus();
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        if (step === 1) {
            if (!agreeTerms) {
                setError('You must agree to the Terms of Use and Privacy Policy.');
                return;
            }

            // Primeira etapa: SignUp com username, email, e senha
            if (user.password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }
            getCode(user.email)
                .then(result => {
                    setCodeSend(result);
                    setStep(2);
                    
                })
                .catch(err => {
                    console.error(err);
                    setError('Invalid code');
                });

        } else if (step === 2) {
            // Segunda etapa: validar o código de verificação
            // Implementar a lógica para verificar o código de verificação
            const codeStr = code.join('');
            if (codeStr === newCode) {
                saveUser(user)
                    .then(result => {
                        // Se o código de verificação for válido, redirecione para a página de login
                        history.push('/');                
                    })
                    .catch(err => {
                        console.error(err.response ? err.response.data : err.message);
                        setError(err.response ? err.response.data : err.message);
                    })
            } else {
                setError('Invalid verification code!');
            }
        }
    }

    return (
        <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
            <div className="container">
                <p className="text-center">
                    <a href="https://goldcoin24k.com" className="d-flex align-items-center justify-content-center">
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path>
                        </svg>
                        Back to homepage
                    </a>
                </p>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="bg-black shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                        <div className="text-center text-md-center mb-4 mt-md-0">
                            {step === 1 && <h1 className="mb-0 h3 text-gray-601">Sign Up for GoldCoin24K</h1>}
                            {step === 2 && <h1 className="mb-0 h4 text-gray-601">Please confirm your email using the code we've just sent.</h1>}
                        </div>
                        <form action="#" className="mt-4" onSubmit={onSubmit}>
                            {step === 1 && (
                                <React.Fragment>
                                    <div className="form-group mb-4">
                                        <label htmlFor="username" className="text-gray-601">Username</label>
                                        <input type="text" className="form-control" placeholder="Enter your username" id="username" autoFocus required onChange={onChangeInput} />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="email" className="text-gray-601">Your Email</label>
                                        <input type="email" className="form-control" placeholder="example@company.com" id="email" required onChange={onChangeInput} />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="password" className="text-gray-601">Your Password</label>
                                        <input type="password" className="form-control" placeholder="Enter your password" id="password" required onChange={onChangeInput} />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="confirmPassword" className="text-gray-601">Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="Confirm your password" id="confirmPassword" required onChange={onChangeInput} />
                                    </div>
                                    <div className="form-group mb-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="agreeTerms" checked={agreeTerms} onChange={onAgreeTermsChange} required />
                                            <label className="form-check-label text-gray-601" htmlFor="agreeTerms">
                                                I agree to the <a href="https://goldcoin24k.com/7803-2/" className="text-decoration-underline">Terms of Use</a> and <a href="https://goldcoin24k.com/privacy-policy/" className="text-decoration-underline">Privacy Policy</a>.
                                            </label>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {step === 2 && (
                                <React.Fragment>
                                    <div className="form-group mb-4">
                                        <label htmlFor="code" className="text-gray-601">Code:</label>
                                        <div className="d-flex justify-content-center align-items-center">
                                            {code.map((digit, index) => (
                                                <input
                                                    id="code"
                                                    key={index}
                                                    ref={(el) => codeInputsRef.current[index] = el}
                                                    type="text"
                                                    className="form-control mx-2 text-center rounded-circle border border-gold"
                                                    style={{ width: '50px', height: '50px' }}
                                                    maxLength="1"
                                                    value={digit}
                                                    onChange={(event) => onCodeChange(index, event.target.value)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="d-grid">
                                <button type="submit" className="btn btn-gray-800"><span className="text-gray-601">{step === 1 ? 'Sign Up' : 'Submit Code'}</span></button>
                            </div>
                            {error && <div className="alert alert-danger mt-2">{error}</div>}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>
    );
}

export default SignUp;