import React, { useState, useEffect, useMemo } from 'react';
import './Dashboard.css';

function CandleChart(props) {
    const [widget, setWidget] = useState({});
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const image = new Image();
            image.onload = () => {
                setImageLoaded(true);
            };
            image.src = "/img/favicon/favicon-32x32_chart.png";
        }, 500); // 2000 milissegundos = 2 segundos
    
        return () => clearTimeout(timeoutId); // Limpa o timeout ao desmontar o componente
    }, []);

    useEffect(() => {
        let symbolForWidget = "BINANCE:" + props.symbol;
        if (props.symbol === "GC24KUSDT") {
            symbolForWidget = "PAXGUSDT";
        }

        const w = new window.TradingView.widget({
            disabled_features: ["legend_widget"],
            symbol: symbolForWidget,
            autosize: true,
            interval: "1",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: true,
            hide_legend: true,
            allow_symbol_change: false,
            details: false,
            withdateranges: true,
            hide_side_toolbar: true,
            disabled_features: [
                "header_symbol_search",
                "header_compare",
                "header_indicators",
                "header_fullscreen_button",
                "header_screenshot",
                "show_hide_button_in_legend",
                "snapshot_trading_drawings",
                //"header_resolutions",
                "legend_context_menu",
                "header_saveload"

            ],      
            studies: [
                "RSI@tv-basicstudies"
            ],
            container_id: "tradingview_d34df"
        });
        setWidget(w);
    }, [props.symbol])

    return (
        <div className="row">
            <div className="col-12 mb-4">
                <div className="card cardDark border-0 shadow colorchart" style={{position: 'relative', height: 520}}>
                    <div className="card-body p-2">
                        <div className="tradingview-widget-container" style={{ position: 'relative' }}>
                            <div id="tradingview_d34df" className="divTradingView" style={{ position: 'relative' }}></div>
                            <div className="mirrorDiv"></div>
                            <div className="mirrorDiv-2"></div>
                        </div>
                        {imageLoaded && (
                            <img src="/img/favicon/favicon-32x32_chart.png" alt="Logo" width="36" height="28" style={{ position: 'absolute', top: 'calc(74% + 10px)', left: '20px' }} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CandleChart;