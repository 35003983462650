import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//import { cancelOrder, syncOrder } from '../../services/OrdersService';
//import { FINISHED_STATUS } from '../../services/ExchangeService';

/**
 * props:
 * - data
 * - onUpdate
 */
function ViewTemplateModal(props) {

    const DEFAULT_TEMPLATE = {
        coin: ''
    }

    const history = useHistory();

    const btnClose = useRef('');
    //const btnCancel = useRef('');
    //const btnSync = useRef('');

    const [template, setTemplate] = useState(DEFAULT_TEMPLATE);

    const [error, setError] = useState('');

    //const [isSyncing, setIsSyncing] = useState(false);

    /*function onSyncClick(event) {
        setIsSyncing(true);
    }*/

    /*
    useEffect(() => {
        if (!isSyncing) return;
        syncOrder(order.id, !!order.positionSide)
            .then(updatedOrder => {
                setIsSyncing(false);
                setOrder(updatedOrder);
            })
            .catch(err => {
                errorHandling(err);
                setIsSyncing(false);
            })
    }, [isSyncing])
    */

    useEffect(() => {
        if (props.data) {
            setTemplate(props.data);

            /*if (btnCancel.current)
                btnCancel.current.disabled = props.data.status !== 'NEW';

            if (btnSync.current)
                btnSync.current.disabled = FINISHED_STATUS.indexOf(props.data.status) !== -1;*/
        }
    }, [props.data])

    function errorHandling(err) {
        console.error(err.response ? err.response.data : err.message);
        setError(err.response ? err.response.data : err.message);
    }

    /*
    function onCancelClick(event) {
        cancelOrder(order.symbol, order.orderId, !!order.positionSide)
            .then(result => {
                setOrder(result);
                if (props.onUpdate)
                    props.onUpdate({ target: { id: 'order', value: result } });
            })
            .catch(err => errorHandling(err))
    }
    */

    function getStatusClass(status) {
        switch (status) {
            case 'REQUIRED': return "badge bg-info";
            case 'FILLED': return "badge bg-success";
            case 'REJECTED':
            case 'EXPIRED':
            case 'NEW_INSURANCE':
            case 'NEW_ADL':
            case 'CANCELED': return "badge bg-danger";
            default: return "badge bg-primary";
        }
    }

    /*
    function getSymbol(order) {
        return order.symbol === 'PAXGUSDT' ? 'GC24KUSDT' : order.symbol;
    }
    */

    function formatarDataHora(valor_original) {
        // Converter para objeto Date
        var data_hora = new Date(valor_original);
    
        // Array para os nomes dos meses
        var meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
        // Extrair partes da data
        var mes = meses[data_hora.getMonth()];
        var dia = data_hora.getDate();
        var ano = data_hora.getFullYear();
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
        var segundos = data_hora.getSeconds();
    
        // Formatar a hora no formato AM/PM
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
    
        // Criar a string formatada
        var data_formatada = mes + ' ' + dia + ', ' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos + ' ' + periodo;
    
        return data_formatada;
    }

    useEffect(() => {
        const modal = document.getElementById('modalViewOrder');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setTemplate({ ...DEFAULT_TEMPLATE });
        })
    }, [])

    return (
        <div className="modal fade" id="modalViewOrder" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title">Withdraw Details</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Coin:</b> {template.coin}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <span className={getStatusClass(template.status)}>{template.status}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>ID:</b> {template.id}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <b>Date:</b> {formatarDataHora(template.createdAt)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Quantity:</b> {template.quantity}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>MarketPrice:</b> {template.marketPrice}
                                    </div>
                                </div>                                                            
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>Commission:</b> {template.fee}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>Total:</b> {template.total}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                error ?
                                    <div className="alert alert-danger mt-1 col-7 py-1">{error}</div>
                                    : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default ViewTemplateModal;
