import React, { useState, useEffect, useMemo } from 'react';
import { getSymbols } from '../../../services/SymbolsService';
import TickerRow from './TickerRow';
//import TickerData from './TickerData';
import SelectQuote, { filterSymbolNames, getDefaultQuote } from '../../../components/SelectQuote/SelectQuote';
import '../Dashboard.css';

/**
 * props:
 * - data: the market data
 */
function Ticker(props) {
    //console.log(props);

    const [symbols, setSymbols] = useState([]);

    const [quote, setQuote] = useState(getDefaultQuote());

    const [gold, setGold] = useState({
        close: '0',
        open: '0',
        high: '0',
        low: '0'
    });

    function onQuoteChange(event) {
        setQuote(event.target.value);
    }

    useEffect(() => {
        getSymbols(false)
            .then(symbols => setSymbols(filterSymbolNames(symbols.rows, quote)))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            })
    }, [quote]);

    const tickerGold = useMemo(() => (
        <tr>
            <td className="text-gray-900">
                GC24KUSDT
            </td>
            <td className="text-gray-900">
                {`${gold.close}`.substring(0, 8)}
            </td>
            <td className="text-gray-900">
                {`${gold.open}`.substring(0, 8)}
            </td>
            <td className="text-gray-900">
                { `${gold.high}`.substring(0, 8)}
            </td>
            <td className="text-gray-900">
                {`${gold.low}`.substring(0, 8)}
            </td>
        </tr>
    ), [gold.close, gold.open, gold.high, gold.low])

    useEffect(() => {
        if (!props.gold || props.gold.c === undefined ) return;

        if (gold.close !== props.gold.c)
            gold.close = props.gold.c;

        if (gold.open !== props.gold.o)
            gold.open = props.gold.o;

        if (gold.high !== props.gold.h)
            gold.high = props.gold.h;

        if (gold.low !== props.gold.l)
            gold.low = props.gold.l;

        setGold(gold);
    }, [props.gold]);


    return (<React.Fragment>
        <div className="col-12 mb-4">
            <div className="card border-0 shadow">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h2 className="fs-5 fw-bold mb-0">Market 24h</h2>
                        </div>
                        <div className="col offset-md-3">
                            <SelectQuote onChange={onQuoteChange} value={quote} isVisible={true}/>
                        </div>
                    </div>
                </div>
                <div className="table-responsive divScroll">
                    <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom" scope="col">SYMBOL</th>
                                <th className="border-bottom col-2" scope="col">CLOSE</th>
                                <th className="border-bottom col-2" scope="col">OPEN</th>
                                <th className="border-bottom col-2" scope="col">HIGH</th>
                                <th className="border-bottom col-2" scope="col">LOW</th>
                            </tr>
                        </thead>
                            <tbody>
                                {tickerGold}
                                {symbols && symbols.length ? (
                                    symbols.map(item => {
                                        if (item === 'GC24KUSDT' || item === 'PAXGUSDT' || item === 'REZUSDT') {
                                            return <React.Fragment></React.Fragment>
                                        }          
                                        return (
                                            <TickerRow key={item} symbol={item} data={props.data[item]} />
                                         );
                                    })

                                ) : (
                                    <React.Fragment />
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default Ticker;
