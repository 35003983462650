import React, { useMemo } from 'react';

/**
 * props:
 * - title
 * - value
 * - precision
 * - background
 */
function InfoGet(props) {

    function getValueText() {

        if (typeof props.value === 'string') return props.value;

        const precision = props.precision !== undefined ? parseInt(props.precision) : 2;

        if (!props.value) return 0;
        const value = parseFloat(props.value);
        if (!value) return 0;

        if (value > 1000000) return `${(value / 1000000).toFixed(precision)}M`;
        if (value > 1000) return `${(value / 1000).toFixed(precision)}k`;
        return value.toFixed(precision);
    }

    function getBackground() {
        if (props.condition) return "icon-shape-modified icon-shape-verde rounded me-4 me-sm-0";
        return `icon-shape-modified icon-shape-vermelho rounded me-4 me-sm-0`;
    }


    const infoGet = useMemo(() => {
        return (          
                    <div className="row d-block d-xl-flex">
                        <div className="col-12 d-flex">
                            <div className={getBackground()}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" /></svg>
                            </div>
                            <div className="ms-3">
                                <h7 className="text-black">{props.text}</h7>
                            </div>
                        </div>
                    </div>
                    

        )
    }, [props.text])

    return infoGet;
}

export default InfoGet;