import axios from './BaseService';

const HYDRA_URL = `${process.env.REACT_APP_API_URL}/phrases/`;

export async function getPhrases() {
    
    const response = await axios.get(HYDRA_URL);
    return response.data;
}

export async function getVerPhrases() {
    
    const response = await axios.get(HYDRA_URL + 'phrases');
    return response.data;
}