import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Ticker from './Ticker/Ticker';
import Book from './Book/Book';
import { getDashboard } from '../../services/DashService';
import { getVerPhrases } from '../../services/PhrasesService';
import InfoBlock from './InfoBlock/InfoBlock';
import useWebSocket from 'react-use-websocket';
import Wallet from '../../components/Wallet/Wallet';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import CandleChart from './CandleChart';
import SelectSymbol from '../../components/SelectSymbol/SelectSymbol';
//import VerificationModal from '../../components/Verification/VerificationModal';

import WithdrawTemplateModal from '../WithdrawTemplates/WithdrawTemplateModal/WithdrawTemplateModal';

import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';

import { useTranslation } from 'react-i18next';

function Dashboard() {

  const { t } = useTranslation();

  const history = useHistory();

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [tickerState, setTickerState] = useState({});

  const [balanceState, setBalanceState] = useState({});

  const [bookState, setBookState] = useState({});

  const [wallet, setWallet] = useState({});

  const [chartSymbol, setChartSymbol] = useState('GC24KUSDT');

  const [report, setReport] = useState({});

  const [notification, setNotification] = useState({ type: '', text: '' });

  const [tickerData, setTickerData] = useState({});

  const [fiatUsd, setFiatUsd] = useState(0);

  const [GC24KValue, setGC24KValue] = useState(0);
    
  const [editWithdrawTemplate, setEditWithdrawTemplate] = useState({});

  function getBinanceWSUrl() {
    const symbol = 'PAXGUSDT';
    return `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@ticker`;
  }

  const { lastJsonMessage: lJsonMessage } = useWebSocket(getBinanceWSUrl(), {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000
  });

  /*
  const checkVerificationStatus = () => {
    // Chame a função no backend para verificar o status de verificação do usuário
    //const userId = localStorage.getItem('id');
    
    getVerPhrases()
      .then(result => {
        console.log(result.hasPhrases);
        if (result.hasPhrases === false) setShowVerificationModal(true);
      })
      .catch(error => {
          console.error(error.response ? error.response.data : error);
          setNotification({ type: 'error', text: error.response ? error.response.data : error });
      })
     
    /*
    fetch(`backend-url/verify/${userId}`)
      .then(response => response.json())
      .then(data => {
        if (!data.verified) {
          // Se o usuário não estiver verificado, abra a modal de verificação
          setShowVerificationModal(true);
        }
      })
      .catch(error => console.error('Error checking verification status:', error));
    
  };
  */

  /*
  useEffect(() => {
    checkVerificationStatus();
  }, []);
  */

  useEffect(() => {
    if (lJsonMessage) {

        setTickerData(lJsonMessage);
        // Supondo que getDashboard() retorna uma Promise
        /*
        getDashboard()
            .then(result => {
                setReport(result);
            })
            .catch(error => {
                console.error(error.response ? error.response.data : error);
                setNotification({ type: 'error', text: error.response ? error.response.data : error });
            });
        */
       
            const gc24kAmount = parseFloat(report.gctotal) * parseFloat(tickerData.a);
            setGC24KValue(gc24kAmount);

    } 
  }, [lJsonMessage]);


  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
    onOpen: () => {
      console.log(`Connected to App WS`);
    },
    onMessage: () => {
      if (lastJsonMessage) {
        if (lastJsonMessage.ticker) {           
          setTickerState(lastJsonMessage.ticker);
          //setReport(prevState => ({ ...prevState, ticker: new Date().toLocaleTimeString('en-US') }));
        }
        else if (lastJsonMessage.balance) {
          setBalanceState(lastJsonMessage.balance);
        }
        else if (lastJsonMessage.book) {
          lastJsonMessage.book.forEach(b => bookState[b.symbol] = b);
          setBookState(bookState);
          //setReport(prevState => ({ ...prevState, book: new Date().toLocaleTimeString('en-US') }));
        }
      }
    },
    queryParams: { 'token': localStorage.getItem("token") },
    onError: (event) => {
      console.error(event);
      setNotification({ type: 'error', text: event });
    },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000
  });

  useEffect(() => {
    getDashboard()
        .then(result => setReport(result))
        .catch(error => {
            console.error(error.response ? error.response.data : error);
            setNotification({ type: 'error', text: error.response ? error.response.data : error });
        })
  }, [])

  function onWithdrawTemplateSubmit(template) {
    history.go(0);
  }


  function onWalletUpdate(walletObj) {
    setWallet(walletObj);
  }

  function onSubmitOrder(order) {
    history.push('/orders/' + order.symbol);
  }

  function onChangeSymbol(event) {
    setChartSymbol(event.target.value);
  }


  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h1 className="h4">{t('Dashboard')}</h1>
          </div>
          <div className="btn-toolbar mb-md-0">
            <div className="d-inline-flex align-items-center">
              <SelectSymbol onChange={onChangeSymbol} symbol={chartSymbol} />
            </div>
            <div className="ms-2 ms-lg-3">
              <NewOrderButton />
            </div>
          </div>
        </div>
        <CandleChart symbol={chartSymbol} />
        <div className="row">
            <InfoBlock title={t('Active Users')} value={parseFloat(report.users)} precision={0} background="warning" coin="USERS">
                <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" /></svg>
            </InfoBlock>
            <InfoBlock title={t('Volume Transaction')} value={parseFloat(report.gctotal).toFixed(2)} precision={2} background="warning" coin="GC24K">
              <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </InfoBlock>
            <InfoBlock title="Total GC24K" value={parseFloat(GC24KValue).toFixed(2)} precision={2} background="warning" coin="USD">
              <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </InfoBlock>
        </div>
        <div className="row">
          <div className="col-12">
            <Ticker data={tickerState} gold={tickerData}/>
          </div>
        </div>
        <div className="row">
          <Book data={bookState} gold={tickerData}/>
          <Wallet data={balanceState} onUpdate={onWalletUpdate}/>
        </div>
        <Footer />
      </main>
      <NewOrderModal wallet={wallet} onSubmit={onSubmitOrder} />
      <WithdrawTemplateModal data={editWithdrawTemplate} onSubmit={onWithdrawTemplateSubmit} />
      {/*<VerificationModal title="Verification" show={showVerificationModal} onClose={() => setShowVerificationModal(false)} />*/}
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default Dashboard;
