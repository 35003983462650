import React from 'react';

/**
 * props:
 * - data
 * - onEditClick
 * - onDeleteClick
 * - onRunClick
 */
function WithdrawTemplateRow(props) {

    function formatarDataHora() {
        // Converter para objeto Date
        var data_hora = new Date(props.data.createdAt);
    
        // Extrair partes da data
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1; // Os meses começam em zero, então adicionamos 1
        var ano = data_hora.getFullYear() % 100; // Apenas os dois últimos dígitos do ano
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
    
        // Formatar a hora no formato AM/PM
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
    
        // Criar a string formatada
        var data_formatada = mes + '/' + dia + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
    
        return data_formatada;
    }

    function getStatus(status) {
        let className;
        switch (status) {
            case 'REQUIRED': className = "badge bg-info py-1"; break;
            case 'FILLED': className = "badge bg-success py-1"; break;
            case 'PROCESSING': className = "badge bg-warning py-1"; break;
            case 'EXPIRED':
            case 'CANCELED': className = "badge bg-danger py-1"; break;
            default: className = "badge bg-warning py-1"; break;
        }
        return (<span className={className}>{status.split('_')[0]}</span>);
    }

    return (
        <tr>
            <td>
                <img  className="me-2" width={16} src={`/img/icons/black/${props.data.coin.toLowerCase()}.svg`} />
                {props.data.coin}
            </td>
            <td><span className="fw-normal">{formatarDataHora()}</span></td>
            <td><span className="fw-normal">{props.data.quantity}</span></td>
            <td>{getStatus(props.data.status)}</td>
            <td>
                <button id={"view" + props.data.id} type="button" className="btn btn-info btn-xs" data-bs-toggle="modal" data-bs-target="#modalViewOrder" onClick={props.onClick}>
                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                    </svg>
                </button>
            </td>
        </tr>
    )
}

export default WithdrawTemplateRow;
