import React, { useMemo } from 'react';

/**
 * props:
 * - title
 * - value
 * - precision
 * - background
 */
function InfoBlock(props) {

    function getValueText() {

        if (props.value === undefined) return 'Loading...';

        if(isNaN(props.value)) return 'Loading...';

        //if (typeof props.value === 'string') return props.value;

        const precision = props.precision !== undefined ? parseInt(props.precision) : 2;

        if (!props.value) return 'Loading...';
        const value = parseFloat(props.value);
        if (!value) return 'Loading...';

        //if (value > 1000000) return `${(value / 1000000).toFixed(precision)}M`;
        //if (value > 1000) return `${(value / 1000).toFixed(precision)}k`;
        return value.toFixed(precision);
    }

    function getBackground() {
        if (!props.background) return "icon-shape icon-shape-primary rounded me-4 me-sm-0";
        return `icon-shape icon-shape-${props.background} rounded me-4 me-sm-0`;
    }

    function getMd() {
        if (!props.md) return "col-md-6 col-sm-6 mb-4 margem margem-right d-inline-block";
        return `col-md-${props.md} col-sm-6 mb-4 margem margem-right d-inline-block`;
    }

    const infoBlock = useMemo(() => {
        return (
            <div className="col-md-4 col-sm-6 mb-4">
                <div className="card border-0 shadow">
                    <div className="card-body bg-gray-800">
                        <div className="row d-block d-xl-flex align-items-center">
                            <div className="col-12 d-flex">
                                <div className={getBackground()}>
                                    {props.children}
                                </div>
                                <div className="ms-3">
                                    <h2 className="h5 text-white">{props.title}</h2>
                                    <h3 className="fw-extrabold d-inline-block text-warning">{getValueText()}</h3>
                                    <div className="d-inline-block ms-2">
                                        <div className="small">
                                            <span className='text-warning fw-bold'>{props.coin}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [props.value])

    return infoBlock;
}

export default InfoBlock;